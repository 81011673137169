/* global Swiper:false anime:false  */

(function () {
	"use strict";

	// swiper for hero slider

	let heroSwiper = null;
	let currentBanner;

	// set boolen to perform the slide animations for one time only
	var animate_gartnerCnapp = true,
		animate_totalCloudRisk = true,
		animate_etmBanner = true,
		animate_totalappsecBanner = true,
		// always keep the regular slides for future use: QSC, CRS events. don't forget to uncomment the slides in the partial first
		animate_crsBanner = true;


	function animateFirstSlide(){
		// place the animation for the first slide here, the animation will be played on swiper load event
		if(animate_totalappsecBanner ){
			animate_totalappsecBanner = false;
			anime({
				targets: ".q-totalappsec .anim",
				translateY: [100, 0],
				opacity: [0, 1],
				duration: 700,
				easing: "easeOutQuint",
				delay: anime.stagger(250)
			});

		}
	}

	const initHeroSwiper = () => {
		heroSwiper = new Swiper(".js-home-hero-slider", {
			slidesPerView: 1,
			centeredSlides: false,
			autoplay: {
				delay: 8000, // duration of each slide in ms
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			},
			autoHeight: false,
			loop: false,
			pagination: {
				el: ".js-home-hero-slider .swiper-pagination",
				clickable: true,
			},
			on: {
				// animate the first slide on load event
				init: animateFirstSlide,
				// animate other slides on slide change event
				slideChange: function () {

					var activeIndex = this.activeIndex;
					var activeSlide = this.slides[activeIndex];
					currentBanner = activeSlide.dataset.banner;

					if(currentBanner === "etmBanner" && animate_etmBanner){
						animate_etmBanner = false;
						anime({
							targets: ".q-etm .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}

				else if(currentBanner === "crsBanner" && animate_crsBanner) {
						animate_crsBanner = false;
						anime({
							targets: ".js-crs-slide-anim .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}
					
					else if (currentBanner === "gartnerCNAPP" && animate_gartnerCnapp) {
						animate_gartnerCnapp = false;
						anime({
							targets: ".js-gartner-cnapp-content .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
						anime({
							targets: ".js-gartner-cnapp-img",
							translateY: [50, 0],
							opacity: [0, 1],
							duration: 750,
							easing: "linear",
							delay: 350
						});
					}

					else if (currentBanner === "totalCloudRisk" && animate_totalCloudRisk) {
						animate_totalCloudRisk = false;
						anime({
							targets: ".js-total-cloud-risk-slide-txt .stgr",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}

				}
			}
		});
	};

	const handleHeroSwiper = () => {
		if (!heroSwiper && document.querySelector(".js-home-hero-slider")) {
			initHeroSwiper();
		}
	};
	window.addEventListener("load", handleHeroSwiper);
	window.addEventListener("resize", handleHeroSwiper);

	//Swiper for Blog container

	let blogSwiper = null;
	const initBlogSwiper = () => {
		blogSwiper = new Swiper(".js-blog-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: {
				el: ".js-blog-container .swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleBlogSwiper = () => {
		if (!blogSwiper && document.querySelector(".js-blog-container")) {
			initBlogSwiper();
		}
	};
	window.addEventListener("load", handleBlogSwiper);
	window.addEventListener("resize", handleBlogSwiper);

	//Swiper for events carousel

	let eventsCarousel = null;
	const initEventsCarousel = () => {
		eventsCarousel = new Swiper(".js-events-carousel", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: false
		});
	};

	const handleEventsCarousel = () => {
		if (!eventsCarousel && document.querySelector(".js-events-carousel")) {
			initEventsCarousel();
		}
	};
	window.addEventListener("load", handleEventsCarousel);
	window.addEventListener("resize", handleEventsCarousel);

	// events slider

	let eventsSlider = null;
	const initEventsSlider = () => {
		eventsSlider = new Swiper(".js-events-slider", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: {
				el: ".js-events-slider .swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleEventsSlider = () => {
		if (!eventsSlider && document.querySelector(".js-events-slider")) {
			initEventsSlider();
		}
	};
	window.addEventListener("load", handleEventsSlider);
	window.addEventListener("resize", handleEventsSlider);

	// init desktop hero total cloud risk carousel
	new Swiper(".q-total-cloud-risk", {
		spaceBetween:50,
		slidesPerView: 2,
		centeredSlides: true,
		loop: true,
		direction: 'vertical',
		navigation: false,
		allowTouchMove: false, // Disable dragging
		speed: 800,
		autoplay: {
			delay: 2500
		},
		effect: 'coverflow',
		coverflowEffect: {
		  rotate: 0,
		  slideShadows: false
		},
		 breakpoints: {
			920: {
				spaceBetween: 150,
			}
		}
	});

	new Swiper(".q-total-cloud-risk-mobile", {
		spaceBetween: 10,
		slidesPerView: 1.3,
		centeredSlides: true,
		loop: true,
		allowTouchMove: false, // Disable dragging
		navigation: false,
		autoplay:true,
		breakpoints: {
			640: {
				spaceBetween:20,
			}
		}
	});

})();